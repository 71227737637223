<template>
  <div class="directflightsonly_top d-flex align-items-center">
    <dropdown-select-direction/>
    <dropdown-select-department/>

    <div class="btn-group" :class="{'opacity-button': !isMe}">
      <div class="form-groupchk">
        <input type="checkbox" id="checkbox1" :checked="isChecked" @change="changeCheck">
        <label for="checkbox1">{{$t("sabre.search-panel.direct-flights-only")}}</label>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dropdownOpenState from '@/sabre/common/mixins/dropdownOpenState';

export default {
  name: 'sabre-search-dropdown-radio',
  mixins: [dropdownOpenState],
  components: {
    DropdownSelectDirection: () => import('../searchPanelAtom/direction/dropdownSelectDirection'),
    DropdownSelectDepartment: () => import('../searchPanelAtom/department/dropdownSelectDepartment'),
  },
  computed: {
    ...mapGetters({
      isChecked: 'GET_SABRE_IS_CHECKED_DIRECT_FLIGHTS_ONLY',
    }),
  },
  mounted() {
    const { query } = this.$route;
    if (query.isDirectFlightsOnly && query.isDirectFlightsOnly === 'true') {
      this.$store.commit('SET_SABRE_IS_CHECKED_DIRECT_FLIGHT_ONLY', true);
    }
  },
  methods: {
    changeCheck() {
      this.$store.commit('SET_SABRE_IS_CHECKED_DIRECT_FLIGHT_ONLY', !this.isChecked);
    },
  },
};

</script>
